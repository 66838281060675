import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }
  Baseurl(): string {
    return Constants.CASA_BASE_URL;
  }

  getCustomerListBySearch(inputData) {
    return this.http.post<any>(this.Baseurl() + Constants.GET_CUSTOMERS_BY_SEARCH, inputData, {observe: 'response'});
  }

  getFinancers(locationId) {
    return this.http.get(this.Baseurl() + Constants.ACCOUNTS_MASTERS_GETFINANCERS+locationId);
  }

}
