import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';


export class HttpErrorInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(retry(1),
        catchError((error: HttpErrorResponse) => {
            let errorMessage: any;
            if (error.error instanceof ErrorEvent) {
              // client-side error
              if(error.status !== 403){
                errorMessage = error.error.message;
              }              
            } else {
              // server-side error
              if(error.status !== 403){
                errorMessage = error.error;
              }              
            }
            Swal.fire({
                type: 'error',
                title: 'Error',
                text: errorMessage
              });
            return throwError(errorMessage);
        }));
    }
}
