import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ModulemappingService {

  constructor(private http:HttpClient) { }

  getModules() {
    return this.http.get<any>(Constants.CASA_BASE_URL + Constants.MODULE_GET_GETMODULES, {observe: 'response'})
  }
  getAssignedModules(opstObject) {
    
    return this.http.post<any>(Constants.CASA_BASE_URL + Constants.MODULE_POST_GETEMPLOYEESFORMODULE, opstObject, { observe: 'response' });
  }
  assignModule(opstObject):any{
    return this.http.post<any>(Constants.CASA_BASE_URL + Constants.MODULE_POST_POSTMODULEASSIGNMENT, opstObject, { observe: 'response' });
  }
}
