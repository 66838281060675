import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class SalesinvoiceService {
  constructor(private http: HttpClient) {}

  Baseurl(): string {
    return Constants.CASA_BASE_URL;
  }
  // Get all Sale Invoices
  getSaleInvoices(inputData: any): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'SalesReport/GetSaleInvoices', inputData);
  }
  getSalesInvoice(inputData: any):any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockOutward/GetSaleInvoice',inputData,{observe:'response'});
  }
  getCustomerDetails(phoneNoOrGst): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'Masters/GetCustomer', phoneNoOrGst, {observe: 'response'});
  }
  updteCustomerWalletFlag(postData): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'Masters/UpdateCustomerWalletFlag', postData, {observe: 'response'});
  }
  // Get the SalesExecutives
  getSalesExecutives(locationId): any {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'Masters/GetEmployeeSalesExecutive/' + locationId);
  }
  // Get the brands
  getBrands(): any {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'Masters/GetBrandList');
  }
  // Get the categories
  getCatagories(): any {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'Masters/GetStockCategoryList');
  }
  getGroups() {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'Masters/GetGroupList');
  }
  getColors() {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'Masters/GetColorList');
  }
  // Get the models
  getModels(): any {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'Masters/GetStockModelList');
  }
  // Get the items
  getItems(inputData): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'Masters/GetStockSearch', inputData, {observe: 'response'});
  }
  // Get Item Details
  getItem(inputData): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'Masters/GetItem', inputData, {observe: 'response'});
  }
  // Get Bracode Information
  getBarcodeInfo(inputData): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'Masters/GetItemPerBarCode', inputData,  {observe: 'response'});
  }
  // create stock inward
  saveSalesInvoice(stockOutwardData): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockOutward/PostSaleInvoice', stockOutwardData, {observe: 'response'});
  }
  printSaleInvoice(inputData: any):any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockOutward/PrintSaleInvoice', inputData, {observe: 'response'});
  }
  getCustomerSearchList(LocationID) {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'Masters/GetCustomerSearchList/' + LocationID);
  }
  getItemDetails(inputData): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'Masters/GetItemDetails', inputData, {observe: 'response'});
  }
  getRedeemPoints(inputData): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'SalesReport/GetRedeemPoints', inputData, {observe: 'response'});
  }
  getLedgers(locationId): any {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'AccountMaster/GetCashBankAccounts/'+locationId);
  }

  getRedeemBalance(inputData): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockOutward/GetRedeemBalance', inputData, {observe: 'response'});
  }
}
