import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AgGridModule } from 'ag-grid-angular';
import { ChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// components
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { LoginComponent } from './public/login/login.component';
import { ErrorComponent } from './public/error/error.component';

// services
import { EmployeeService } from './shared/employee-service.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpErrorInterceptor } from './shared/http-error.interceptor';
// import { NgxBarcodeModule } from 'ngx-barcode';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LoaderComponent } from './shared/loader/loader/loader.component';
import { LoaderInterceptor } from './shared/http-loader.interceptor';
import { LoaderService } from './services/loader/loader.service';
import { LoginService } from './services/login/login.service';
import { ErrorNotFoundComponent } from './public/error-not-found/error-not-found.component';
import { HomeComponent } from './public/home/home.component';
import { BannerComponent } from './shared/banner/banner.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoginComponent,
    ErrorComponent,
    ErrorNotFoundComponent,
    LoaderComponent,
    HomeComponent,
    BannerComponent,
  
  ],
  imports: [
    BrowserModule,
    NgSelectModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
    ChartsModule,
    // NgxBarcodeModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    LoaderService,
    Title,
    EmployeeService,
    LoginService,
    DatePipe,
    DecimalPipe,
    NgCircleProgressModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
