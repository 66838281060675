import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators  } from '@angular/forms';
import Swal from 'sweetalert2';
import { ItemcomponentService } from 'src/app/erp/services/itemcomponent/itemcomponent.service';
import { LoginService } from 'src/app/services/login/login.service';


@Component({
  selector: 'app-itemcomponent',
  templateUrl: './itemcomponent.component.html',
  styleUrls: ['./itemcomponent.component.css']
})
export class ItemcomponentComponent implements OnInit {

  constructor(private fb: FormBuilder, private itemService: ItemcomponentService,
              private loginService: LoginService) { }

  public user: any;
  public itemList: any = [];
  public catagories: any = [];
  public groups: any = [];
  public brands: any = [];
  public models: any = [];
  public temparatureColors: any = [];
  public bodyColors: any = [];
  public capacity: any = [];
  public items: any = [];
  public loading = false;
  public itemCodes: any = [];

  itemForm = this.fb.group({
    LocationID: null,
    Brand: null,
    Category: null,
    Group: null,
    TemparatureColor: null,
    BodyColor: null,
    Model: null,
    Capacity: null,
    ItemID: null,
    ItemClientID: null,
    ItemCode_ItemName: null,
    ItemName: '',
    SalePrice: '',
    IGST: null,
    CGST: null,
    SGST: null,
    DefaultSalesDiscount: null,
  });

  ngOnInit() {
    this.user = this.loginService.getUser();
    this.getCatagories();
  }

   // to get categories
   getCatagories() {
    this.itemService.getCatagories().subscribe(res => {
      this.itemList = [];
      let resData = {};
      res.forEach(item => {
        resData = {
          ID: item.CategoryID,
          Code: item.CategoryName
        };
        this.itemList.push(resData);
      });
      this.catagories = this.itemList;
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }

  // to get categories
  getGroups(event) {
    if (event) {
      this.itemService.getGroupsPerCategory(event.ID).subscribe(res => {
        this.itemList = [];
        let resData = {};
        res.forEach(item => {
          resData = {
            ID: item.GroupID,
            Code: item.GroupName
          };
          this.itemList.push(resData);
        });
        this.groups = this.itemList;
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error
        });
      });
    }
  }


  // to get brands
  getBrands(event) {
    if (event) {
      this.itemService.getBrandsPerGroup(event.ID).subscribe(res => {
        this.itemList = [];
        let resData = {};
        res.forEach(item => {
          resData = {
            ID: item.BrandID,
            Code: item.BrandName
          };
          this.itemList.push(resData);
        });
        this.brands = this.itemList;
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error
        });
      });
    }
  }

  // to get models
  getModels(event) {
    if (event) {
      this.itemService.getModelsPerBrand(event.ID).subscribe(res => {
        this.itemList = [];
        let resData = {};
        res.forEach(item => {
          resData = {
            ID: item.ModelID,
            Code: item.ModelName
          };
          this.itemList.push(resData);
        });
        this.models = this.itemList;
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error
        });
      });
    }
  }

  getColorCapacity(event) {
    if (event) {
      this.getColors(event);
      this.getCapacity(event);
    }
  }

   // to get colors
   getColors(event) {
    if (event) {
      this.itemService.getTemperatureColorsPerModel(event.ID).subscribe(res => {
        this.itemList = [];
        let resData = {};
        res.forEach(item => {
          resData = {
            ID: item.ColorID,
            Code: item.ColorName
          };
          this.itemList.push(resData);
        });
        this.temparatureColors = this.itemList;
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error
        });
      });

      this.itemService.getBodyColorListPerModel(event.ID).subscribe(res => {
        this.itemList = [];
        let resData = {};
        res.forEach(item => {
          resData = {
            ID: item.ColorID,
            Code: item.ColorName
          };
          this.itemList.push(resData);
        });
        this.bodyColors = this.itemList;
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error
        });
      });
    }
  }

   // to get models
   getCapacity(event) {
    if (event) {
      this.itemService.getCapacityPerModel(event.ID).subscribe(res => {
        this.itemList = [];
        let resData = {};
        res.forEach(item => {
          resData = {
            ID: item.CapacityID,
            Code: item.CapacityName
          };
          this.itemList.push(resData);
        });
        this.capacity = this.itemList;
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error
        });
      });
    }
  }

  // to get items
  getItems() {
    const inputData = {
      CategoryId: this.itemForm.value.Category,
      GroupID: this.itemForm.value.Group,
      BrandId: this.itemForm.value.Brand,
      ModelId: this.itemForm.value.Model,
      ColorID: this.itemForm.value.TemparatureColor,
      BodyColorId: this.itemForm.value.BodyColor,
      CapacityId: this.itemForm.value.Capacity
    };
    this.itemService.getItems(inputData).subscribe(res => {
      this.itemList = [];
      let resData = {};
      if (res.status === 200 && res.body) {
        res.body.forEach(item => {
          resData = {
            ID: item.ItemID,
            Code: item.ItemSearch,
            ItemClientID: item.ItemClientID,
            ItemName: item.ItemName,
            ItemID: item.ItemID,
            SalePrice: item.SalePrice,
            IGST: item.IGST,
            CGST: item.CGST,
            SGST: item.SGST,
            DefaultSalesDiscount: item.DefaultSalesDiscount
          };
          this.itemList.push(resData);
        });
        this.items = this.itemList;
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: 'Oops.. Something went wrong, Try again'
        });
      }
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }

  getItemLabel(event) {
    if (event) {
      this.itemForm.patchValue({ItemName: event.ItemName});
      this.itemForm.patchValue({ItemID: event.ID});
      this.itemForm.patchValue({ItemClientID: event.ItemClientID});
      this.itemForm.patchValue({SalePrice: event.SalePrice});
      this.itemForm.patchValue({IGST: event.IGST});
      this.itemForm.patchValue({CGST: event.CGST});
      this.itemForm.patchValue({SGST: event.SGST});
      this.itemForm.patchValue({DefaultSalesDiscount: event.DefaultSalesDiscount});
    }
  }

  getItemLabelSearch(event) {
    if (event) {
      this.itemForm.patchValue({ItemID: event.ID});
      this.itemForm.patchValue({ItemName: event.ItemName});
      this.itemForm.patchValue({ItemCode_ItemName: event.ID});
      this.itemForm.patchValue({ItemClientID: event.ItemClientID});
      this.itemForm.patchValue({SalePrice: event.SalePrice});
      this.itemForm.patchValue({IGST: event.IGST});
      this.itemForm.patchValue({CGST: event.CGST});
      this.itemForm.patchValue({SGST: event.SGST});
      this.itemForm.patchValue({DefaultSalesDiscount: event.DefaultSalesDiscount});
    }
  }

  getItemCodes(event) {
    if (event) {
      const inputData = {
        ItemCode_ItemName: event
      };
      if (event.length > 3) {
        setTimeout(() => {
          this.loading = true;
          this.itemService.getItems(inputData).subscribe(res => {
            this.itemList = [];
            let resData = {};
            if (res.status === 200 && res.body) {
              res.body.forEach(item => {
                resData = {
                  ID: item.ItemID,
                  Code: item.ItemSearch,
                  ItemClientID: item.ItemClientID,
                  ItemName: item.ItemName,
                  ItemID: item.ItemID,
                  SalePrice: item.SalePrice,
                  IGST: item.IGST,
                  CGST: item.CGST,
                  SGST: item.SGST,
                  DefaultSalesDiscount: item.DefaultSalesDiscount
                };
                this.itemList.push(resData);
              });
              this.itemCodes = this.itemList;
              this.loading = false;
            } else {
              Swal.fire({
                type: 'error',
                title: 'Error',
                text: 'Oops.. Something went wrong, Try again'
              });
            }
          }, error => {
            Swal.fire({
              type: 'error',
              title: 'Error',
              text: error
            });
          });
        }, 2000);
      }
    }
  }

  clearModalData() {
    this.itemForm.patchValue({Category: null});
    this.itemForm.patchValue({Group: null});
    this.itemForm.patchValue({Brand: null});
    this.itemForm.patchValue({Model: null});
    this.itemForm.patchValue({TemparatureColor: null});
    this.itemForm.patchValue({BodyColor: null});
    this.itemForm.patchValue({Capacity: null});
    this.itemForm.patchValue({ItemID: null});
    this.itemForm.patchValue({ItemCode_ItemName: null});
    this.itemForm.patchValue({ItemName: ''});
    this.itemForm.patchValue({IGST: null});
    this.itemForm.patchValue({CGST: null});
    this.itemForm.patchValue({SGST: null});
  }


}
