import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { ErrorComponent } from './public/error/error.component';
import { ErrorNotFoundComponent } from './public/error-not-found/error-not-found.component';
import { HomeComponent } from './public/home/home.component';
 
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'accounts', loadChildren: './accounts/accounts.module#AccountsModule' },
  { path: 'ecommerce', loadChildren: './ecommerce/ecommerce.module#EcommerceModule'},
  { path: 'erp',  loadChildren: './erp/erp.module#ErpModule'},
  { path: 'hrms',  loadChildren: './hrms/hrms.module#HrmsModule'},
  { path: 'service',  loadChildren: './service-module/service.module#ServiceModule'},
  { path: '**', component: ErrorNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
