import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/app/shared/constants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  authToken: any;
  user: any;
  role: any;
  permissions: any;
  sideBarMenu: any;

  constructor(private http: HttpClient,private route:Router) { }

  Baseurl(): string {
    return Constants.CASA_BASE_URL;
  }
  getPendingInwardsCount(locationId): any { 
    return this.http.get<any>(this.Baseurl() + 'StockReports/GetBalanceOutwardsCountOnly/' + locationId);
  }
  getPendingInwards(locationId): any { 
    return this.http.get<any>(this.Baseurl() + 'StockReports/GetBalanceOutwards/' + locationId);
  }
  getPendingActions(){
    return this.http.get<any>(this.Baseurl() + 'Masters/GetPendingActions');
  }
  
  savePendingActions(postData) {
    return this.http.post<any>(this.Baseurl() + 'Masters/PostActionItem', postData, {observe: 'response'});
  }
  // Get the Locations
  getLocationCodes(): any {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetAllLocationList');
  }

  getMenus(postData): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/ValidateModule', postData, {observe: 'response'});
  }

  // Get the Finyears
  getFinancialYears(locationId): any { 
    return this.http.get<any>(this.Baseurl() + 'Masters/GetFinancialYear/' + locationId);
  }

  login(userData): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/ValidateUserNew', userData, {observe: 'response'});
  }
  
  savePassword(inputData){
    return this.http.post<any>(this.Baseurl() + 'Masters/SavePassword', inputData, {observe: 'response'});
  }
  generateForgotPasswordOTP(inputData){
    return this.http.post<any>(this.Baseurl()+'Masters/GenerateForgotPassowrdOTP',inputData,{observe:'response'});
  }
  confirmForgotPasswordOTP(inputData){
    return this.http.post<any>(this.Baseurl()+'Masters/ConfirmForgotPasswordOTP',inputData,{observe:'response'});
  }
  sendDailyStatus(inputData): any {
    return this.http.post<any>(this.Baseurl() + 'SalesStatus/SendDailyStatus', inputData,{observe: 'response'});
  }
  sendReOrderNotification(inputData): any {
    return this.http.post<any>(this.Baseurl() + 'SalesStatus/SendReOrderNotification', inputData,{observe: 'response'});
  }
  makeActiveInactiveNotifications(inputData): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/MakeNotificationsActiveOrInactive', inputData,{observe: 'response'});
  }
  /*Logout block starts*/
  updateLogoutStatus(userData): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/Logout', userData, { observe: 'response' });
  }
  logout(): any {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
    this.route.navigate(['/login']);
    return;
  }
  /*Logout block ends*/
  storeUserData(token: any, user: any): void {
    console.log(user);
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  loadToken() {
    this.authToken = localStorage.getItem('token');
  }
  getToken(): string {
    return localStorage.getItem('token');
  }
  getUser(): any {
    return JSON.parse(localStorage.getItem('user'));
  }
  getSideMenu(): any {
    return JSON.parse(localStorage.getItem('user'));
  }
  changepassword(userObj): any {
    return this.http.post<any>(this.Baseurl() + 'ChangePassword', userObj);
  }
  
}
