import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Constants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {
  constructor(private http: HttpClient) { }

  Baseurl(): string {
    return Constants.CASA_BASE_URL;
  }

  createSupplier(supplier): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'Masters/PostSupplier', supplier, { observe: 'response' });
  }

  getCountry(): Observable<any> {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'Masters/GetCountryList');
  }

  getState(country): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'Masters/GetStateList', country, { observe: 'response' });
  }
  
  getSuppliers(locationId) {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'Masters/GetSupplierList/'+locationId);
  }

  getDebtorAccounts(locationId) {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'AccountMaster/GetDebtorAccounts/'+locationId);
  }

}
