import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { Constants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(private http: HttpClient) { }

  Baseurl(): string {
    return Constants.CASA_BASE_URL;
  }

  createCustomer(customer): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/PostCustomer', customer, { observe: 'response' });
  }
  
  getCreditorAccounts(locationId) {
    return this.http.get<any>(this.Baseurl() + 'AccountMaster/GetCreditorAccounts/'+locationId);
  }

  getCustomers(locationId): Observable<any> {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetCustomerList/'+locationId);
  }

  getState(): Observable<any> {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetStateList');
  }

  addCostCenter(costCenter): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/PostCustomerCostCenter', costCenter, { observe: 'response' });
  }
  
  getCostCenter(inpuData):  any {
    return this.http.post<any>(this.Baseurl() + 'Masters/GetCostCenterByCustomer', inpuData, { observe: 'response' });  
  }

}
