import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  apiURL: string = 'http://devenv.shreeyogi.com/Api';
  sharedUser = {};
  constructor( private http: HttpClient ) { }

  getRoles(): Observable<any> {
    return this.http.get<any> (`${this.apiURL}/Masters/GetRoleList`);
  }

  getLocations(): Observable<any> {
    return this.http.get<any> (`${this.apiURL}/Masters/GetLocationList`);
  }

}
