import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class StockReportsService {

  constructor(private http: HttpClient) {}
  Baseurl(): string {
    return Constants.CASA_BASE_URL;
  }
  //Below service is for Product list
  getAllItems() {
    return this.http.get<any>(Constants.CASA_BASE_URL + 'StockReports/GetAllItems', {observe: 'response'})
  }
  postPhysicalStock(opstObject) {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockReports/AdjustStock', opstObject, { observe: 'response' });
  }
  getClosingStock(opstObject): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockReports/GetStockInventory', opstObject, { observe: 'response' });
  }
  getStockLedger(opstObject): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockReports/GetStockLedger', opstObject, { observe: 'response' });
  }
  getDamageStockLedger(opstObject): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockReports/GetDamageStockLedger', opstObject, { observe: 'response' });
  }
  getStockBatches(opstObject):any{
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockReports/GetStockBatch', opstObject, { observe: 'response' });
  }
  getStockBatchOutwards(opstObject):any{
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockReports/GetStockBatchOutwards', opstObject, { observe: 'response' });
  }
  getItemBarCodes(inputObj): any {
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockReports/GetItemBarCodes', inputObj, { observe: 'response' });
  }
  getStockClosingBalance(opstObject):any{
    return this.http.post<any>(Constants.CASA_BASE_URL + 'StockReports/GetStockClosingBalance', opstObject, { observe: 'response' });
  }
}
