import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class SalesSourceService {

  constructor(public http:HttpClient) { }

  Baseurl(): string {
    return Constants.CASA_BASE_URL;
  }

  getSalesSource(locationId){
    return this.http.get<any>(this.Baseurl()+ 'Masters/GetSalesSource/'+locationId);
  }

  createSalesSource(sales): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/PostSalesSourceMaster', sales, { observe: 'response' });
  }

  // GetPaymentAccounts
  getPaymentAccounts(locationId): any {
    return this.http.get<any>(this.Baseurl()+ 'AccountMaster/GetPaymentAccounts/'+locationId);
  }

}
