import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Constants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  constructor(private http: HttpClient) { }

  Baseurl(): string {
    return Constants.CASA_BASE_URL;
  }

  getInventory(inputData): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/GetLocationStock', inputData, { observe: 'response' });
  }
  //GetActiveOrInactiveStock
  getActiveOrInactiveStock(inputData): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/GetActiveOrInactiveStock', inputData, { observe: 'response' });
  }
  createInventory(inputData): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/PostLocationStock', inputData, { observe: 'response' });
  }
  updateInventoryToActiveOrInactive(inputData):any{
    return this.http.post<any>(this.Baseurl() + 'Masters/PostStockToActiveOrInactive', inputData, { observe: 'response' });
  }
  generateBarcodes(inputData): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/GetBarCodes', inputData, { observe: 'response' });
  }
  postOnlineItemStatus(inputData): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/PostOnlineItemStatus', inputData, { observe: 'response' });
  }
  createCategoryOneCommerce(categoryData){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic Y2tfYTg4MDNmZTEyNjA0ZjczODg3NDBmOTBiYmU5MzU1ODA5ZWI1MjY2YTpjc181YTkxNzEwYzg0NDY5YTI3OGI0YWQ1MDFmZGE0ZWYxNDFjNTY3ODg0');
    headers = headers.append('Content-Type', 'application/json');
     return this.http.post<any>(Constants.ECOMMERCE_BASE_URL + 'wc/v3/products/categories', categoryData, {headers});
  }
  createGroupOneCommerce(groupData){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic Y2tfYTg4MDNmZTEyNjA0ZjczODg3NDBmOTBiYmU5MzU1ODA5ZWI1MjY2YTpjc181YTkxNzEwYzg0NDY5YTI3OGI0YWQ1MDFmZGE0ZWYxNDFjNTY3ODg0');
    headers = headers.append('Content-Type', 'application/json');
     return this.http.post<any>(Constants.ECOMMERCE_BASE_URL + 'wc/v3/products/categories', groupData, {headers});
  }
  createItemsOneCommerce(itemData) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic Y2tfYTg4MDNmZTEyNjA0ZjczODg3NDBmOTBiYmU5MzU1ODA5ZWI1MjY2YTpjc181YTkxNzEwYzg0NDY5YTI3OGI0YWQ1MDFmZGE0ZWYxNDFjNTY3ODg0');
    headers = headers.append('Content-Type', 'application/json');
     return this.http.post<any>(Constants.ECOMMERCE_BASE_URL + 'wc/v3/products', itemData, {headers});
  }
  geteCommerceItemId(erpProductId) {
    return this.http.get<any>(Constants.ECOMMERCE_BASE_URL + 'getproduct/byerpid/' + erpProductId);
  }
  geteCommerceItemDetails(eCommerceProductId):any {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic Y2tfYTg4MDNmZTEyNjA0ZjczODg3NDBmOTBiYmU5MzU1ODA5ZWI1MjY2YTpjc181YTkxNzEwYzg0NDY5YTI3OGI0YWQ1MDFmZGE0ZWYxNDFjNTY3ODg0');
    headers = headers.append('Content-Type', 'application/json');
    return this.http.get<any>(Constants.ECOMMERCE_BASE_URL + 'wc/v3/products/' + eCommerceProductId,{headers});
  }
  updateItemsOneCommerce(itemId, itemData){
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', 'Basic Y2tfYTg4MDNmZTEyNjA0ZjczODg3NDBmOTBiYmU5MzU1ODA5ZWI1MjY2YTpjc181YTkxNzEwYzg0NDY5YTI3OGI0YWQ1MDFmZGE0ZWYxNDFjNTY3ODg0');
    headers = headers.append('Content-Type', 'application/json');
    return this.http.post<any>(Constants.ECOMMERCE_BASE_URL + 'wc/v3/products/' + itemId, itemData,{headers});
  } 

  UpdateECommerceCategoryID(postData) {
    return this.http.post<any>(this.Baseurl() + 'Masters/UpdateECommerceCategoryID', postData, { observe: 'response' });
  }
  UpdateECommerceGroupID(postData) {
    return this.http.post<any>(this.Baseurl() + 'Masters/UpdateECommerceGroupID', postData, { observe: 'response' });
  }
  UpdateECommerceItem(postData) {
    return this.http.post<any>(this.Baseurl() + 'Masters/UpdateECommerceItem', postData, { observe: 'response' });
  }
}
