import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Constants } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ItemcomponentService {

  constructor(private http: HttpClient) {}

  Baseurl(): string {
    return Constants.CASA_BASE_URL;
  }

  // Get the categories
  getCatagories(): any {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetStockCategoryList');
  }

  getAllItems(): any {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetAllItems');
  }

  getGroups(id: number) {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetGroupListPerCategory/' + id);
  }

  getAllGroups(id: number) {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetGroupList/' + id);
  }
  // Get the brands
  getBrands(id: number): any {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetBrandListPerGroup/' + id);
  }

  getAllBrands(id: number): any {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetBrandList/' + id);
  }

  // Get the models
  getModels(id: number): any {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetStockModelListPerBrand/' + id);
  }

  getAllModels(id: number): any {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetStockModelList/' + id);
  }

  getTemparatureColors(id: number): any {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetTemperatureColorList/' + id);
  }

  getBodyColors(id: number): any {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetBodyColorList/' + id);
  }

  getCapacity(id: number): any {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetCapacityList/' + id);
  }

  // Get the items
  getItems(inputData): any {
    return this.http.post<any>(this.Baseurl() + 'Masters/GetStockSearch', inputData, {observe: 'response'});
  }

  getGroupsPerCategory(id: number) {
    return this.http.get<any>(this.Baseurl() + Constants.GROUP_GET_GROUPS_PER_CATEGORY  + '/' + id);
  }

  getModelsPerBrand(id: number){
    return this.http.get<any>(this.Baseurl() + Constants.MODEL_GET_MODELS_PER_BRAND + '/' + id);
  }

  getBrandsPerGroup(id){
    return this.http.get<any>(this.Baseurl() + Constants.BRAND_GET_BRANDS_PER_GROUP  + '/' + id);
  }

  getBodyColorListPerModel(modelId): any {
    return this.http.get<any>(this.Baseurl() + Constants.COLOR_BODY_GET_LIST_PER_MODEL + '/' + modelId);
  }

  getTemperatureColorsPerModel(modelId) {
    return this.http.get<any>(this.Baseurl() + Constants.COLOR_TEMPERATURE_GET_LIST_PER_MODEL + '/' + modelId);
  }

  getCapacityPerModel(id: number): any {
    return this.http.get<any>(this.Baseurl() + 'Masters/GetCapacityListPerModel/' + id);
  }

}
