import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login/login.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public itemList: any = [];
  public locations: any = [];
  public sideBarMenu: any = [];
  public user: any;
  constructor( private loginService: LoginService ) { }

  ngOnInit() {
    this.user = this.loginService.getUser();
    this.getMenuList();
  }

  getMenuList() {
    const moduleID = localStorage.getItem('moduleId');
    const postData = {
      LocationID: this.user.LocationID,
      EmployeeCode: this.user.EmployeeCode,
      ModuleID: moduleID,
      YearNo: this.user.YearNo
    };
    this.loginService.getMenus(postData).subscribe(res => {
      if (res.body) {
        this.sideBarMenu = res.body.Menu;
      }
    });
  }
}
