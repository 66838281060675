import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, Validators  } from '@angular/forms';
import { LoginService } from '../../services/login/login.service';
import { StockReportsService } from 'src/app/erp/services/reports/stockreports/stockreports.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(private fb: FormBuilder,private route: Router, private loginService: LoginService, private stockReportService: StockReportsService) { }

  itemList: any = [];
  locationCodes: any = [];
  financialYears: any = [];

  showSendOTP:boolean = true;
  otpSuccessMss:boolean = false;
  confirmOTP:boolean = false;
  changePasswodForm:boolean = false;

  loginObject: any = {
    LocationID: null,
    EmployeeCode: null,
    Password: null,
  };

  loginForm = this.fb.group({
    LocationID: [null,Validators.required],
    EmployeeCode: [null,Validators.required],
    Password: [null,Validators.required],
    FinancialYear: [null,Validators.required],
  });

  forgotPasswordForm = this.fb.group({
    OTP:null,
    currentPassword:[null, Validators.required],
    newPassword:[null, Validators.required],
    confirmPassword:[null, Validators.required],
    LocationID:[null,Validators.required],
    CreateUserID: '',
    EmployeeCode:''

  });

  ngOnInit() {
    if (this.loginService.getToken()) {
      this.route.navigate(['/home']);
    } else {
      this.getLocationCodes();
      this.route.navigate(['/login']);
      this.loginService.logout();
    }
    //this.resetPasswordFrom();   
  }
  clearPasswordFrom() {

  }
  forgotPasswordSendOPT(){

    let fromValidation:boolean;
    fromValidation= this.forgotPasswordForm.valid;
   
    let locationId=this.forgotPasswordForm.value.LocationID;
    let employeeCode=this.forgotPasswordForm.value.EmployeeCode;
    
    if(locationId==null||locationId==undefined||employeeCode==""){
      return;
    }
    let inputData={
      LocationID:this.forgotPasswordForm.value.LocationID,
      EmployeeCode:this.forgotPasswordForm.value.EmployeeCode,
    };
    this.loginService.generateForgotPasswordOTP(inputData).subscribe(res=>{
      if(res)
      {
        this.confirmOTP=true;
        this.otpSuccessMss=true;
        this.showSendOTP = false; 
      }
    });    
  }
  confirmForgotPasswordOTP(){
   
    let inputData = {

      LocationID:this.forgotPasswordForm.value.LocationID,
      EmployeeCode:this.forgotPasswordForm.value.EmployeeCode,
      OTP:this.forgotPasswordForm.value.OTP

    };
    this.loginService.confirmForgotPasswordOTP(inputData).subscribe(res=>{
      if(res)
      {
        this.confirmOTP=false;
        this.otpSuccessMss=false;
        this.showSendOTP = false;
        this.changePasswodForm = true; 
      }
    }); 
  }
  savePassword(){

    let newPassword = this.forgotPasswordForm.value.newPassword;
    let confirmPassword = this.forgotPasswordForm.value.confirmPassword;

    this.loginService.savePassword(this.forgotPasswordForm.value).subscribe(res=>{

      if(res.status ===  201){
        this.resetPasswordFrom();
      }
    });
     
  }
  resetPasswordFrom(){
    this.forgotPasswordForm = this.fb.group({
      currentPassword:[null, Validators.required],
      newPassword:[null, Validators.required],
      confirmPassword:[null, Validators.required],
      CreateUserID: this.user.EmployeeCode,
      EmployeeCode: this.user.EmployeeCode,
      LocationID:this.user.LocationID
    });
  }

  // To get Locations
  getLocationCodes() {
    this.loginService.getLocationCodes().subscribe(res => {
      this.itemList = [];
      let resData = {};
      res.forEach(item => {
        resData = {
          ID: item.LocationID,
          Code: item.Name
        };
        this.itemList.push(resData);
      });
      this.locationCodes = this.itemList;
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }

  // to get items
  getFinancialYears() {
    const locationCode = this.loginForm.value.LocationID;
    this.loginService.getFinancialYears(locationCode).subscribe(res => {
      this.itemList = [];
      let resData = {};
      if (res) {
        res.forEach(item => {
          const fromYear = new Date(item.YearFrom);
          const toYear =  new Date(item.YearTo);
          const finYear = fromYear.getFullYear() + ' - ' + toYear.getFullYear();
          resData = {
            ID: item.YearNo,
            Code: finYear
          };
          this.itemList.push(resData);
        });
        this.financialYears = this.itemList;
      } else {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: 'Oops.. Something went wrong, Try again'
        });
      }
    }, error => {
      Swal.fire({
        type: 'error',
        title: 'Error',
        text: error
      });
    });
  }
  itemsData:any[] = [];
  user:any;
  getItemsData() {
    this.user = this.loginService.getUser();
    this.itemsData = [];
    const inputObj = {
      LocationID: 1,
      CategoryId: 0,
    };
    this.stockReportService.getClosingStock(inputObj).subscribe(
      result => {
        if (result.body.length > 0) {
          this.itemList = [];
          let resData = {};
          result.body.forEach(item => {
            resData = {
              ID: item.ItemID,
              Code: item.DisplayText
            };
            this.itemList.push(resData);
          });
          this.itemsData = this.itemList;
        }
      });
  }
  login() {
    this.getItemsData();
    if (!this.loginForm.invalid) {
      this.loginObject.LocationID = this.loginForm.value.LocationID;
      this.loginObject.EmployeeCode = this.loginForm.value.EmployeeCode;
      this.loginObject.Password = this.loginForm.value.Password;
      this.loginObject.YearNo = this.loginForm.value.FinancialYear;
      this.loginService.login(this.loginObject).subscribe(res => {
      
        if (res.status === 200) {
          const user = res.body;
          this.loginService.storeUserData(
            res.accessToken,
            user
          );
          this.loginForm.patchValue({LocationID: null, password: '', EmployeeCode: '', FinancialYear: null});
          location.reload();
         
        } else {
          Swal.fire('Login Fail.', 'Please check email and password.', 'error');
        }
      }, error => {
        Swal.fire({
          type: 'error',
          title: 'Error',
          text: error
        });
      });
     
    } else {
      Swal.fire('Oops..', 'Please fill all fields.', 'error');
    }
  }
}



